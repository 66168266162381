import { Link } from 'gatsby';
import React from 'react';
import Layout from '../../components/layout';
import SEO from "../../components/seo"

const ConvertPage = () => {
  return(
    <Layout activeGroup="convert">
      <SEO title="Convert data" description="Convert json, csv, xml data" />
      <h1 className="text-3xl font-bold text-gray-900">Convert data between types</h1>
      <div className="grid grid-cols-12 gap-4 pt-12">

        <div className="col-span-4">
          <h2 className="text-xl font-bold">JSON</h2>
          <div className="pt-4">
            <h3 className="text-blue-600 hover:text-blue-800"><Link to="/convert/json-csv">JSON to CSV</Link></h3>
            <h3 className="text-blue-600 hover:text-blue-800"><Link to="/convert/json-xml">JSON to XML</Link></h3>
          </div>
        </div>

        <div className="col-span-4">
          <h2 className="text-xl font-bold">CSV</h2>
          <div className="pt-4">
            <h3 className="text-blue-600 hover:text-blue-800"><Link to="/convert/csv-json">CSV to JSON</Link></h3>
          </div>
        </div>

        <div className="col-span-4">
          <h2 className="text-xl font-bold">XML</h2>
          <div className="pt-4">
            <h3 className="text-blue-600 hover:text-blue-800"><Link to="/convert/xml-json">XML to JSON</Link></h3>
          </div>
        </div>

      </div>
    </Layout>
  )
};

export default ConvertPage;